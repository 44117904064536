var prevScrollpos = window.pageYOffset;
var timer = null;
var new_scroll = true;
var scroll_star = true;

var scrollToYPosition = function (elementPosition) {

  // Codigo anterior
  // window.scrollTo(0, elementPosition);

  // asigna la nueva posicion de scroll con un efecto de transición
  window.scrollTo({
    left: 0, top: elementPosition, behavior: 'smooth'
  });

  new_scroll = false; //

}

/**
 * Se ejecuta siempre que se detecte un evento scroll.
 * Se utiliza 'timer' para controllar que se ejecute el codigo al primer llamado del evento scroll, es decir, evita multiples llamados de multiples eventos
 */
window.onscroll = function ( e ) {

  /**
   * Condición que controla la ejecución del scroll al seleccionar la nav lateral.
   * Si 'scroll_star' es verdadero se reasigna el scroll.
   * Si 'scroll_star' es falso no se reasigna y se cambia el valor de 'scroll_star' a verdadero para permitir la ejecución del scroll
   */
  if( window.scroll_star ){

    if (timer) clearTimeout(timer);

    timer = setTimeout(function () {

      scrollOrientation();

    }, 250);

  }else{

    window.scroll_star = true;

  }

}

/**
 * Determina la dirección del scroll
 */
function scrollOrientation( ){

    var currentScrollPos = window.pageYOffset;
    var navbar = document.getElementById("navbar");
    if (prevScrollpos > currentScrollPos) {

      if (navbar && navbar.classList.contains('animate__fadeOutUp')) {
        navbar.classList.remove('animate__fadeOutUp');
      }

      if (navbar && navbar.classList.remove('sticky-NOheader')) {
        navbar.classList.remove('sticky-NOheader');
      }

      if (navbar) {
        navbar.classList.add('animate__fadeIn');
        navbar.classList.add('sticky-header');
      }

      scrollPage('up');

    }else {

      if (navbar && navbar.classList.contains('animate__fadeIn')) {
        navbar.classList.remove('animate__fadeIn');
      }
      if (navbar && navbar.classList.contains('sticky-header')) {
        navbar.classList.remove('sticky-header');
      }

      if (navbar) {
        navbar.classList.add('sticky-NOheader');
        navbar.classList.add('animate__fadeOutUp');
      }

      scrollPage('down');
    }
    prevScrollpos = currentScrollPos;

}

function scrollPage(orientation = 'down') {


  if( new_scroll ){ // controla la ejecución del codigo cuando se reasigna la posición del scroll

    var sections = document.querySelectorAll('.section');

    // obtener la navegación de las secciones
    var section_list = document.querySelectorAll('.sections-nav-item');


    /**
      Busca la posicion de la sección activa actualmente
    */
    let id_encontrado;
    section_list.forEach(( section, index) => {

      if( section.getAttribute('class').includes('active') ){

        id_encontrado = index;

      }

    });

    if( orientation == 'down' ){

      if(sections[ id_encontrado + 1 ]){ // valida si la sección siguiente existe para realizar el desplazamiento

        // seleccionamos la sección siguiente
        addClassActive( `a[href="#${sections[ id_encontrado + 1 ].getAttribute('id')}"]` );
        scrollToYPosition(sections[ id_encontrado + 1 ].offsetTop);

      }

    }else{

      if( sections[ id_encontrado - 1 ] ){ // valida si la sección anterior existe para realizar el desplazamiento
        // seleccionamos la sección anterior
        addClassActive( `a[href="#${sections[ id_encontrado - 1 ].getAttribute('id')}"]` );
        scrollToYPosition(sections[ id_encontrado - 1 ].offsetTop);

      }

    }

  }else{

    new_scroll = true; // activa la ejecución de un nuevo evento scroll

  }

  // var positionOfSections = [];
  // var currentPosition = prevScrollpos;

  // for (var section of sections) {
  //   var sectionPosition = section.offsetTop;
  //   positionOfSections.push(sectionPosition);
  // }

  // var bannerPosition = positionOfSections[0];
  // var sliderServicePosition = positionOfSections[1];
  // var wePosition = positionOfSections[2];
  // var servicesNotDigitalPosition = positionOfSections[3];
  // var phcWorldPosition = positionOfSections[4];
  // var testimonyPosition = positionOfSections[5];
  // var projectsPosition = positionOfSections[6];
  // var footerPosition = positionOfSections[7];

  // if (currentPosition > bannerPosition && currentPosition < sliderServicePosition) {
  //   if (orientation === 'up') {
  //     scrollToYPosition(bannerPosition);
  //     addClassActive('a[href="#banner"]');
  //   } else if (orientation === 'down') {
  //     scrollToYPosition(sliderServicePosition);
  //     addClassActive('a[href="#sliderservicio"]');
  //   }
  // }

  // if (currentPosition > sliderServicePosition && currentPosition < wePosition) {
  //   if (orientation === 'up') {
  //     scrollToYPosition(sliderServicePosition);
  //     addClassActive('a[href="#sliderservicio"]');
  //   } else if (orientation === 'down') {
  //     scrollToYPosition(wePosition);
  //     addClassActive('a[href="#nosotros"]');
  //   }
  // }

  // if (currentPosition > wePosition && currentPosition < servicesNotDigitalPosition) {
  //   if (orientation === 'up') {
  //     scrollToYPosition(wePosition);
  //     addClassActive('a[href="#nosotros"]');
  //   } else if (orientation === 'down') {
  //     scrollToYPosition(servicesNotDigitalPosition);
  //     addClassActive('a[href="#serviciosNoDigitales"]');
  //   }
  // }

  // if (currentPosition > servicesNotDigitalPosition && currentPosition < phcWorldPosition) {
  //   if (orientation === 'up') {
  //     scrollToYPosition(servicesNotDigitalPosition);
  //     addClassActive('a[href="#serviciosNoDigitales"]');
  //   } else if (orientation === 'down') {
  //     scrollToYPosition(phcWorldPosition);
  //     addClassActive('a[href="#PHCMundo"]');
  //   }
  // }

  // if (currentPosition > phcWorldPosition && currentPosition < testimonyPosition) {
  //   if (orientation === 'up') {
  //     scrollToYPosition(phcWorldPosition);
  //     addClassActive('a[href="#PHCMundo"]');
  //   } else if (orientation === 'down') {
  //     scrollToYPosition(testimonyPosition);
  //     addClassActive('a[href="#testimonios"]');
  //   }
  // }

  // if (currentPosition > testimonyPosition && currentPosition < projectsPosition) {
  //   if (orientation === 'up') {
  //     scrollToYPosition(testimonyPosition);
  //     addClassActive('a[href="#testimonios"]');
  //   } else if (orientation === 'down') {
  //     scrollToYPosition(projectsPosition);
  //     addClassActive('a[href="#proyectos"]');
  //   }
  // }

  // if (currentPosition > projectsPosition && currentPosition < footerPosition) {
  //   if (orientation === 'up') {
  //     scrollToYPosition(projectsPosition);
  //     addClassActive('a[href="#proyectos"]');
  //   } else if (orientation === 'down') {
  //     scrollToYPosition(footerPosition);
  //     addClassActive('a[href="#footer"]');
  //   }
  // }

  // if (currentPosition > footerPosition) {
  //   if (orientation === 'up') {
  //     scrollToYPosition(footerPosition);
  //     addClassActive('a[href="#footer"]');
  //   }
  // }
}

function addClassActive(selector) {
  if (selector) {
    var sectionActive = document.querySelector('#sections-nav a.active');
    if (sectionActive) {
      sectionActive.classList.remove('active');
    }
    var section = document.querySelector(selector);
    section.classList.add('active');
  }
}
